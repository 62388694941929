import gql from "graphql-tag";

export const videoIdeaGql = gql`
  query VideoIdea($id: ID!) {
    videoIdea(id: $id) {
      ... on VideoIdea {
        id
        created
        modified
        title
        thumbnail
        shortDescription
        longDescription
        isActive
        script
        videoUrls
        types {
          id
          name
        }
        purposes {
          id
          name
        }
        audiences {
          id
          name
        }
        industries {
          id
          name
        }
        duration
        lastOrderScript {
          id
          text
        }
      }

      ... on ResponseErrors {
        errors {
          message
          displayMessage
        }
      }
    }
  }
`;
