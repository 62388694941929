import gql from "graphql-tag";

export const latestOrderQuotationGql = gql`
  query LatestOrderQuotation {
    latestOrder {
      ... on Order {
        id
        quotation {
          id
          created
          order {
            id
            status {
              key
            }
          }
          status {
            id
            key
          }
          hasSubmitted
          choosePerformers
          performersPreference
          returnProducts
          sendProducts
          useSameDayDelivery
          useExpress
          useExtendedVideoLength
          usePerformerTravel
          usePremiumPerformers
          hireStrategist
          numOfVideos
          numOfPerformers
          promoCode {
            id
            code
            expiry
            isActive
            durationInMonths
            discountPerVideo
            discountFromTotal
          }
          totalPrice
          useTestimonialPackage
          testimonialLockInMonths
          revisionRequests
        }
      }

      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
