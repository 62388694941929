import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "text-center mt-16"
}
const _hoisted_3 = { class: "video-grid__empty-message flex my-60" }
const _hoisted_4 = { class: "m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResultCard = _resolveComponent("ResultCard")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!

  return (_ctx.loading || _ctx.loadMoreLoading || !_ctx.isEmpty(_ctx.videoIdeas))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onScroll: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.scrollHandler && _ctx.scrollHandler(...args)))
      }, [
        _createElementVNode("div", {
          class: "video-grid",
          id: _ctx.scrollElementId
        }, [
          (_ctx.loading && !_ctx.loadMoreLoading)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.DEFAULT_PAGE_SIZE, (num) => {
                return (_openBlock(), _createBlock(_component_ResultCard, {
                  key: num,
                  loading: _ctx.loading
                }, null, 8, ["loading"]))
              }), 128))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.videoIdeas, (videoIdea) => {
                return (_openBlock(), _createBlock(_component_ResultCard, _mergeProps({
                  key: videoIdea.id,
                  id: videoIdea.id,
                  title: videoIdea.title,
                  descriptions: videoIdea.shortDescription,
                  thumbnailSrc: videoIdea.thumbnail,
                  "extra-subtitle": _ctx.videoLengthTextFormat(videoIdea.duration),
                  created: videoIdea.created,
                  onOnClick: ($event: any) => (_ctx.$emit('on-item-click', videoIdea.id, videoIdea.title))
                }, _ctx.resultCardProps), null, 16, ["id", "title", "descriptions", "thumbnailSrc", "extra-subtitle", "created", "onOnClick"]))
              }), 128))
        ], 8, _hoisted_1),
        (_ctx.hasNextPage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.loadMoreLoading && _ctx.autoLoadMore)
                ? (_openBlock(), _createBlock(_component_a_skeleton, {
                    key: 0,
                    active: ""
                  }))
                : (!_ctx.autoLoadMore)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 1,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-load-more'))),
                      loading: _ctx.loadMoreLoading
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Load more")), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"]))
                  : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ], 32))
    : _renderSlot(_ctx.$slots, "empty", { key: 1 }, () => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_typography_title, { level: 2 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("No items in list.")), 1)
              ]),
              _: 1
            })
          ])
        ])
      ])
}