import { unref, Ref, reactive, computed, watch } from "vue";
import { useVideoIdeas } from "@/shared/composables/useVideoIdeas";
import { VideoIdeas_videoIdeas_VideoIdeaResults_results } from "@/api/video/__generated__/VideoIdeas";

type VideoIdeaId = VideoIdeas_videoIdeas_VideoIdeaResults_results["id"];

/** Contains all the loaded video details from VideoIdeas endpoint */
const publicVideosCache = reactive<
  Record<VideoIdeaId, VideoIdeas_videoIdeas_VideoIdeaResults_results>
>({});

export const usePublicVideoIdeas = (keyword: string | Ref<string> = "") => {
  const keywordRef = computed<string>(() => unref(keyword));

  const {
    /** Current video ideas based on search results */
    videoIdeasList: publicVideoIdeas,
    videoIdeasLoading: publicVideoIdeasLoading,
    videoIdeasFetchMore: publicVideoIdeasFetchMore,
    videoIdeasFetchMoreLoading: publicVideoIdeasFetchMoreLoading,
    videoIdeasHasNextPage: publicVideoIdeasHasNextPage,
    searchFilter,
  } = useVideoIdeas(15, {
    isPublic: true,
  });

  // Triggers re-fetch
  watch(keywordRef, (keyword) => {
    searchFilter.query = keyword;
  });

  /** Caches result in composable global-scope. Creates new values, updates old values. */
  watch(publicVideoIdeas, (videoIdeas) => {
    videoIdeas.forEach((video) => {
      if (video) publicVideosCache[video.id] = video;
    });
  });

  /** Helper function to return video details using id */
  const getVideoIdeaDetails = (id: VideoIdeaId) => {
    return publicVideosCache[id];
  };

  return {
    publicVideosCache,
    publicVideoIdeas,
    publicVideoIdeasLoading,
    publicVideoIdeasFetchMore,
    publicVideoIdeasFetchMoreLoading,
    publicVideoIdeasHasNextPage,
    getVideoIdeaDetails,
  };
};
