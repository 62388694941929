import { debounce, isEmpty } from "lodash";

const options = {
  elementId: "",
  callback: () => {},
  pause: () => false,
};

/** Add debounce to avoid multiple execution while fast scrolling.
 * Must be placed here to make it unique function so that
 * removeEventListener will work. */
const createDebouncedScrollFunction = (additionalOffset = 100) =>
  debounce(() => {
    if (!isEmpty(options.elementId) && !options.pause()) {
      /** Get offset of from the element's bottom edge to the current view
       * screen's top */
      const element = window.document.getElementById(options.elementId);
      const elementBCR = element?.getBoundingClientRect();
      const elementHeight = elementBCR?.height ?? 0;
      const elementTop = elementBCR?.top ?? 0;
      /** Add offset in pixels to delay trigger to make it more natural
       * and dynamic looking */
      const offsetFromViewScreen =
        elementHeight + elementTop + additionalOffset;

      /** If the offset is already smaller or equal to the current view's height
       * (Hence measuring from it's bottom edge) */
      if (offsetFromViewScreen <= window.innerHeight) {
        options.callback();
      }
    }
  }, 100);

export const useExecuteOnScrollToElement = (
  elementId: string,
  callback: () => void,
  pause: () => boolean,
  additionalOffset = 100
) => {
  const onScroll = createDebouncedScrollFunction(additionalOffset);

  const initialize = () => {
    options.elementId = elementId;
    options.callback = callback;
    options.pause = pause;
    window.addEventListener("scroll", onScroll);
  };

  const destroy = () => {
    window.removeEventListener("scroll", onScroll);
  };

  return {
    initialize,
    destroy,
  };
};
