import {
  CreateQuotationInput,
  UpdateQuotationInput,
} from "@/../__generated__/globalTypes";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { latestQuotationGql } from "@/api/quotations/latestQuotation";
import { createQuotationGql } from "@/api/quotations/createQuotation";
import { updateQuotationGql } from "@/api/quotations/updateQuotation";
import {
  CreateQuotation,
  CreateQuotationVariables,
  CreateQuotation_createQuotation_Quotation,
} from "@/api/quotations/__generated__/CreateQuotation";
import {
  UpdateQuotation,
  UpdateQuotationVariables,
  UpdateQuotation_updateQuotation_Quotation,
} from "@/api/quotations/__generated__/UpdateQuotation";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import isEmpty from "lodash/isEmpty";

export const useUpdateQuotation = () => {
  // === Create Quotation ===
  const { mutate: createQuotationMutate, loading: createQuotationLoading } =
    useCustomMutation<CreateQuotation, CreateQuotationVariables>(
      createQuotationGql,
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: latestQuotationGql, fetchPolicy: "network-only" },
        ],
      }
    );

  const createQuotation = async (input: CreateQuotationInput) => {
    const response = await createQuotationMutate({ input });

    const parsedResponse =
      parseGqlResponse<CreateQuotation_createQuotation_Quotation>(
        "Quotation",
        response
      );

    if (!isEmpty(parsedResponse.error?.errors) || !response) {
      throw new Error("Failed to create quotation");
    }

    return parsedResponse.data;
  };

  // === Update Quotation ===
  const { mutate: updateQuotationMutate, loading: updateQuotationLoading } =
    useCustomMutation<UpdateQuotation, UpdateQuotationVariables>(
      updateQuotationGql
    );

  const updateQuotation = async (input: UpdateQuotationInput) => {
    console.log("UpdateQuotationInput", input);

    const response = await updateQuotationMutate({ input });

    const parsedResponse =
      parseGqlResponse<UpdateQuotation_updateQuotation_Quotation>(
        "Quotation",
        response
      );

    if (!isEmpty(parsedResponse.error?.errors) || !response) {
      throw new Error("Failed to update quotation");
    }

    return parsedResponse.data;
  };

  return {
    createQuotation,
    createQuotationLoading,

    updateQuotation,
    updateQuotationLoading,
  };
};
