import { computed, onMounted } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { latestQuotationGql } from "@/api/quotations/latestQuotation";
import {
  LatestQuotation,
  LatestQuotation_latestQuotation_Quotation,
} from "@/api/quotations/__generated__/LatestQuotation";
import { apiErrorCodes } from "@/shared/utils/constants";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useUpdateQuotation } from "@/shared/composables/useUpdateQuotation";

export const useLatestQuotation = () => {
  // === Queries ===
  const {
    result: latestQuotationResult,
    loading: latestQuotationLoading,
    refetch: refetchlatestQuotation,
  } = useQuery<LatestQuotation>(latestQuotationGql);

  const {
    createQuotation,
    createQuotationLoading: reuseLatestQuotationLoading,
  } = useUpdateQuotation();

  const latestQuotation = computed(() => {
    const parsedResponse =
      parseGqlResponse<LatestQuotation_latestQuotation_Quotation>(
        "Quotation",
        latestQuotationResult.value,
        apiErrorCodes.INVALID_AUTH_TOKEN
      );

    return parsedResponse.data;
  });

  const latestQuotationHasOrder = computed(
    () => !!latestQuotation.value?.order
  );

  const shouldAskForQuotation = computed(
    () =>
      // Ask for new Quotation if not yet submitted,
      !latestQuotation.value?.hasSubmitted ||
      // or if submitted, but the number of videos is empty
      // (for extra safety since some Quote has been falsely tagged as submitted
      // due to previous workaround)
      (latestQuotation.value?.hasSubmitted &&
        !latestQuotation.value?.numOfVideos)
  );

  const tryReuseLatestQuotation = async () => {
    await createQuotation({ reuseQuoteFromLatestOrder: true });
    await refetchlatestQuotation();
  };

  onMounted(() => {
    refetchlatestQuotation();
  });

  return {
    latestQuotation,
    latestQuotationLoading,
    refetchlatestQuotation,

    latestQuotationHasOrder,
    shouldAskForQuotation,
    tryReuseLatestQuotation,
    reuseLatestQuotationLoading,
  };
};
