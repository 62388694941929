import gql from "graphql-tag";

export const createQuotationGql = gql`
  mutation CreateQuotation($input: CreateQuotationInput!) {
    createQuotation(input: $input) {
      ... on Quotation {
        id
        order {
          id
          status {
            key
          }
        }
        hasSubmitted
        choosePerformers
        performersPreference
        returnProducts
        sendProducts
        useSameDayDelivery
        useExpress
        useExtendedVideoLength
        usePerformerTravel
        usePremiumPerformers
        hireStrategist
        numOfVideos
        numOfPerformers
        promoCode {
          id
          code
          expiry
          isActive
          durationInMonths
          discountPerVideo
        }
        totalPrice
        useTestimonialPackage
        testimonialLockInMonths
        revisionRequests
        pricesJson
        priceMatrix
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
