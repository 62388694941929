
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useGlobalModal } from "@/shared/composables/useGlobalModal";
import { userQuotationAddOnsList } from "@/shared/composables/useQuotationOptions";
import { useLatestOrder } from "@/shared/composables/useLatestOrder";
import { useUpdateQuotation } from "@/shared/composables/useUpdateQuotation";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import routeNames from "@/web/router/routeNames";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import LayoutIcon from "@/shared/components/Icons/LayoutIcon.vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const { isGlobalModalVisible, openGlobalModal, closeGlobalModal } =
      useGlobalModal("REUSE_QUOTATION");

    const { latestOrderQuotation, latestOrderLoading } = useLatestOrder(true);

    const { createQuotation, createQuotationLoading, updateQuotationLoading } =
      useUpdateQuotation();

    const { redirectToQuote, redirectToQuoteSummary } = useRedirectHelper();

    const handleRevise = async () => {
      const newQuotation = await createQuotation({
        reuseQuoteFromLatestOrder: false,
      });
      if (newQuotation) {
        if (routeNames.quote != route.name) {
          redirectToQuote({
            confirmed: true,
          });
        }
        closeGlobalModal();
      }
    };

    const handleUseTheSame = async () => {
      const newQuotation = await createQuotation({
        reuseQuoteFromLatestOrder: true,
      });
      if (newQuotation) {
        redirectToQuoteSummary({
          quotationId: newQuotation?.id,
        });
        closeGlobalModal();
      }
    };

    const { getFilteredAddOnsList } = userQuotationAddOnsList();

    const filteredAddOnsList = computed(() =>
      getFilteredAddOnsList(latestOrderQuotation?.value)
    );

    const loading = computed(
      () =>
        latestOrderLoading.value ||
        createQuotationLoading.value ||
        updateQuotationLoading.value
    );

    return {
      t,
      isGlobalModalVisible,
      openGlobalModal,
      closeGlobalModal,
      handleRevise,
      handleUseTheSame,
      latestOrderQuotation,
      filteredAddOnsList,
      loading,
      latestOrderLoading,
    };
  },
  components: {
    AppIcon,
    LayoutIcon,
  },
});
