
import { VideoIdeas_videoIdeas_VideoIdeaResults_results } from "@/api/video/__generated__/VideoIdeas";
import ResultCard from "@/shared/components/ResultCard.vue";
import { defineComponent, onMounted, onUnmounted, PropType } from "vue";
import { useI18n } from "vue-i18n";
import isEmpty from "lodash/isEmpty";
import { DEFAULT_PAGE_SIZE } from "@/shared/utils/constants";
import { formatDurationFromSeconds } from "@/shared/utils/date";
import { videoLengthTextFormat } from "@/shared/utils/video";
import { useExecuteOnScrollToElement } from "@/shared/composables/useScrollFunctions";

/** Can be a union type of different gql _results types using | */
export type VideoIdeasListItem = VideoIdeas_videoIdeas_VideoIdeaResults_results;

/** Props for ResultCard component */
export type ResultCardProps = InstanceType<typeof ResultCard>["$props"];

/**
 * A generic grid to display Video Ideas as cards in a grid.
 */
export default defineComponent({
  props: {
    /** Video */
    videoIdeas: {
      type: Array as PropType<VideoIdeasListItem[]>,
      required: true,
    },
    hasNextPage: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
    loadMoreLoading: {
      type: Boolean,
      default: false,
    },
    resultCardProps: {
      type: Object as PropType<ResultCardProps>,
    },
    autoLoadMore: {
      type: Boolean,
      default: false,
    },
    autoLoadMoreReset: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    /** The ID of the element to base for the "useExecuteOnScrollToElement" logic
     * for the auto load more feature */
    const scrollElementId = "video-grid";

    const { initialize: initAutoLoad, destroy: destroyAutoLoad } =
      useExecuteOnScrollToElement(
        scrollElementId,
        () => emit("on-load-more"),
        () => props.loadMoreLoading || !props.hasNextPage
      );

    onMounted(() => {
      if (props.autoLoadMore) {
        initAutoLoad();
      }
    });

    onUnmounted(() => {
      if (props.autoLoadMore) {
        destroyAutoLoad();
      }
    });

    return {
      t,
      isEmpty,
      DEFAULT_PAGE_SIZE,
      scrollElementId,
      formatDurationFromSeconds,
      videoLengthTextFormat,
      initAutoLoad,
      destroyAutoLoad,
    };
  },
  emits: ["on-item-click", "on-load-more"],
  components: {
    ResultCard,
  },
});
