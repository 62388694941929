import gql from "graphql-tag";

export const addToCartGql = gql`
  mutation AddToCart($input: AddToCartInput!) {
    addToCart(input: $input) {
      ... on Cart {
        id
        items {
          id
          text
          videoIdea {
            id
            title
          }
        }
        notes
        itemCount
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
