import gql from "graphql-tag";

export const relatedVideoIdeasGql = gql`
  query RelatedVideoIdeas($input: RelatedVideoIdeasInput!) {
    relatedVideoIdeas(input: $input) {
      ... on RelatedVideoIdeaResults {
        results {
          id
          title
          thumbnail
          purposes {
            id
            name
          }
          created
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
