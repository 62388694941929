import gql from "graphql-tag";

export const updateQuotationGql = gql`
  mutation UpdateQuotation($input: UpdateQuotationInput!) {
    updateQuotation(input: $input) {
      ... on Quotation {
        id
        order {
          id
        }
        hasSubmitted
        created
        modified
        numOfVideos
        numOfPerformers
        choosePerformers
        performersPreference
        returnProducts
        sendProducts
        useExpress
        useExtendedVideoLength
        usePerformerTravel
        usePerformerTravel
        usePremiumPerformers
        hireStrategist
        totalPrice
        useTestimonialPackage
        testimonialLockInMonths
        promoCode {
          id
          code
        }
        revisionRequests
        pricesJson
        priceMatrix
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message

          ... on InvalidInputError {
            field
          }
        }
      }
    }
  }
`;
