import gql from "graphql-tag";

export const updateCartNotesGql = gql`
  mutation UpdateCartNotes($input: UpdateCartNotesInput!) {
    updateCartNotes(input: $input) {
      ... on Cart {
        id
        notes
        itemCount
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
