import { RelatedVideoIdeasInput } from "@/../__generated__/globalTypes";
import { relatedVideoIdeasGql } from "@/api/video/relatedVideoIdeas";
import {
  RelatedVideoIdeas,
  RelatedVideoIdeasVariables,
  RelatedVideoIdeas_relatedVideoIdeas,
  RelatedVideoIdeas_relatedVideoIdeas_RelatedVideoIdeaResults,
} from "@/api/video/__generated__/RelatedVideoIdeas";
import { useQuery } from "@vue/apollo-composable";
import get from "lodash/get";
import { computed, reactive, ref } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { DEFAULT_PAGE_SIZE } from "@/shared/utils/constants";
import { HideIfErrorCode } from "@/shared/utils/graphql/errorHandler";

const defaultPagination: RelatedVideoIdeasInput["pagination"] = {
  after: null,
  pageSize: DEFAULT_PAGE_SIZE,
};

export const useRelatedVideoIdeas = (
  id: string,
  hideErrors = false,
  pageSize = DEFAULT_PAGE_SIZE
) => {
  const inputVariables = reactive<RelatedVideoIdeasInput>({
    id: id,
    pagination: {
      ...defaultPagination,
      pageSize: pageSize, // custom page size
    },
  });

  const {
    result: relatedVideoIdeasResult,
    loading: relatedVideoIdeasLoading,
    refetch: relatedVideoIdeasRefetch,
    fetchMore,
  } = useQuery<RelatedVideoIdeas, RelatedVideoIdeasVariables>(
    relatedVideoIdeasGql,
    () => ({
      input: inputVariables,
    }),
    () => ({
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true, // to notify loading when refetch is triggered manually
    })
  );

  // parsed data from backend
  const relatedVideoIdeasParsedData = computed(() => {
    return parseGqlResponse<RelatedVideoIdeas_relatedVideoIdeas_RelatedVideoIdeaResults>(
      "RelatedVideoIdeaResults",
      relatedVideoIdeasResult.value,
      hideErrors ? HideIfErrorCode.ALL_ERRORS : null
    ).data;
  });

  const relatedVideoIdeasPageInfo = computed(
    () => relatedVideoIdeasParsedData.value?.pageInfo
  );

  // fetch more
  const relatedVideoIdeasFetchMoreLoading = ref(false);
  const relatedVideoIdeasFetchMore = async () => {
    // Only proceed if has next page and theres an end cursor
    if (
      relatedVideoIdeasPageInfo.value?.hasNextPage &&
      relatedVideoIdeasPageInfo.value.endCursor
    ) {
      // set loading, use this to determine if load more is called
      relatedVideoIdeasFetchMoreLoading.value = true;

      await fetchMore({
        variables: {
          input: {
            ...inputVariables,
            pagination: {
              after: relatedVideoIdeasPageInfo.value.endCursor,
              pageSize: pageSize,
            },
          },
        },
        /**
         * manually update graphql query result
         *
         * this could easily fail because types used in union are incompatible
         * add type assertion to manually update the result type
         */
        updateQuery: (prevResult, { fetchMoreResult }) => {
          /**
           * Clone the previous result then concatenate the fetch more result
           */
          const updatedRelatedVideoIdeas = {
            ...prevResult.relatedVideoIdeas,
            // use fetchMoreResult page info but use previous as fallback
            pageInfo: get(
              fetchMoreResult?.relatedVideoIdeas,
              "pageInfo",
              prevResult?.relatedVideoIdeas?.["pageInfo"]
            ),
            // concatenate previous result and fetch more results
            results: [
              ...get(prevResult?.relatedVideoIdeas, "results", []),
              ...get(fetchMoreResult?.relatedVideoIdeas, "results", []),
            ],

            // use new total count
            totalCount: get(
              fetchMoreResult?.relatedVideoIdeas,
              "totalCount",
              prevResult?.relatedVideoIdeas?.["totalCount"]
            ),
            __typename: prevResult.relatedVideoIdeas?.__typename,
          };

          return {
            relatedVideoIdeas: <RelatedVideoIdeas_relatedVideoIdeas>(
              updatedRelatedVideoIdeas
            ),
          };
        },
      });
      relatedVideoIdeasFetchMoreLoading.value = false; // reset loading
    }
  };

  /**
   * If have to be queried manually
   *
   * In normal circumstances there's no need to call this manually,
   * any update to the filters will trigger refetch automatically
   */
  const relatedVideoIdeasFetch = async () => {
    await relatedVideoIdeasRefetch();
  };

  return {
    relatedVideoIdeasParsedData,
    relatedVideoIdeasList: computed(
      () => relatedVideoIdeasParsedData.value?.results ?? []
    ),
    relatedVideoIdeasLoading,
    relatedVideoIdeasFetch,
    relatedVideoIdeasFetchMore,
    relatedVideoIdeasFetchMoreLoading,
    relatedVideoIdeasHasNextPage: computed(
      () => relatedVideoIdeasPageInfo.value?.hasNextPage
    ),
  };
};
