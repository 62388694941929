
import InputTextArea from "@/shared/components/Forms/InputTextArea.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import VideoEmbedCarousel from "@/shared/components/VideoEmbed/VideoEmbedCarousel.vue";
import VideoGrid from "@/shared/components/Videos/VideoGrid.vue";
import { useAuthentication } from "@/shared/composables/useAuthentication";
import { useCart } from "@/shared/composables/useCart";
import { useGlobalModal } from "@/shared/composables/useGlobalModal";
import { usePublicVideoIdeas } from "@/shared/composables/usePublicVideoIdeas";
import { useRelatedVideoIdeas } from "@/shared/composables/useRelatedVideoIdeas";
import { useLatestQuotation } from "@/shared/composables/useLatestQuotation";
import { useVideoIdea } from "@/shared/composables/useVideoIdea";
import { CommonAlertTypes as messageType } from "@/shared/utils/alert";
import {
  analyticsTrackCartAdd,
  analyticsTrackVideoDetailsView,
  analyticsTrackVideoRevisedScript,
} from "@/shared/utils/analytics";
import { formatDurationFromSeconds } from "@/shared/utils/date";
import message from "@/shared/utils/message";
import { createRedirectedRoute } from "@/shared/utils/routerUtils";
import routeNames from "@/web/router/routeNames";
import { EditOutlined, HistoryOutlined } from "@ant-design/icons-vue";
import { isEmpty } from "lodash";
import {
  capitalize,
  computed,
  defineComponent,
  reactive,
  watch,
  watchEffect,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { videoLengthTextFormat } from "@/shared/utils/video";
import ReuseQuotationModal from "@/shared/components/ReuseQuotationModal.vue";

const parseVideoUrls = (urls: string[]) => {
  return urls.map((s) => s.trim()).filter((s) => s !== "");
};

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const { redirectToVideoDetails } = useRedirectHelper();

    const { isAuthenticated } = useAuthentication();
    const { shouldAskForQuotation, latestQuotationHasOrder } =
      useLatestQuotation();
    const { openGlobalModal: openReuseQuotationModal } =
      useGlobalModal("REUSE_QUOTATION");
    const { openGlobalModal: openAskForQuotationModal } =
      useGlobalModal("ASK_FOR_QUOTATION");

    const id = String(router.currentRoute.value.params.id);
    const cartItemId = String(router.currentRoute.value.query.cartItemId);
    const isPublicView = computed<boolean>(
      () =>
        String(router.currentRoute.value.query.public) === "true" ||
        !isAuthenticated.value
    );

    // Queries - VideoIdea (Fetched or Public)
    const { getVideoIdeaDetails } = usePublicVideoIdeas();
    const {
      error,
      loading,
      videoIdea: fetchedVideoIdea,
    } = useVideoIdea({ id }, isPublicView.value);
    const videoIdea = computed(() =>
      isPublicView.value ? getVideoIdeaDetails(id) : fetchedVideoIdea.value
    );

    // Queries - Related Video Ideas
    const {
      relatedVideoIdeasList,
      relatedVideoIdeasLoading,
      relatedVideoIdeasParsedData,
    } = useRelatedVideoIdeas(id, isPublicView.value);

    // Queries - Cart & Subscription Plan
    const {
      handleAddToCart,
      cartList,
      addToCartLoading,
      updateCartScript,
      updateCartScriptLoading,
      getCustomScript,
      refetchCart,
    } = useCart(!isPublicView.value);

    const parsedVideoUrls = computed(() =>
      parseVideoUrls(videoIdea.value?.videoUrls ?? [])
    );

    const formState = reactive({
      script: "",
      /** Variable to hold value while editing, after reverting to original */
      temporaryScript: "",
      isEditingScript: false,
    });

    const defaultScript = computed(() => videoIdea.value?.script ?? "");
    const cartScript = computed(() => getCustomScript(cartItemId));

    const hasDifferentScript = computed(
      () =>
        (isAddedToCart.value &&
          cartScript.value &&
          cartScript.value !== defaultScript.value) ||
        (!isAddedToCart.value && !!lastOrderScript.value)
    );
    /** Used to enable/disable submit button */
    const hasEditedScript = computed<boolean>(
      () =>
        // Consider updated if added to cart, and current value != saved in cart, OR
        (isAddedToCart.value && formState.script !== cartScript.value) ||
        // Consider updated if not yet added to cart, and value != default
        (!isAddedToCart.value && formState.script !== defaultScript.value)
    );

    const isAddedToCart = computed(
      () => !!cartList.value.find((item) => item?.id === cartItemId)
    );

    const lastOrderScript = computed(() => {
      if (videoIdea.value && "lastOrderScript" in videoIdea.value) {
        return videoIdea.value.lastOrderScript?.text ?? "";
      }
      return "";
    });

    // Show notification on error
    watch(error, (value) => {
      if (value) {
        message[messageType.error]({
          content: value.message,
        });
      }
    });

    // Set default values of formState on load
    watchEffect(() => {
      if (videoIdea.value) {
        const scriptToLoad =
          cartScript.value || lastOrderScript.value || videoIdea.value.script;

        formState.temporaryScript = scriptToLoad;
        formState.script = scriptToLoad;

        if (hasDifferentScript.value) {
          formState.isEditingScript = true; // Set mode automatically to editing
        }
      }
    });

    // === Analytics ===
    watch(videoIdea, (value) => {
      if (value?.id) {
        analyticsTrackVideoDetailsView(value);
      }
    });

    // === Handlers ===

    const addToCart = async () => {
      console.log("addToCart");

      if (!videoIdea.value) return;

      analyticsTrackCartAdd(videoIdea.value);
      analyticsTrackVideoRevisedScript(
        formState.script,
        videoIdea.value?.script ?? "",
        videoIdea.value?.title ?? ""
      );

      try {
        await handleAddToCart({
          videoIdeaId: id,
          script:
            // Only save custom script if not the same as original
            formState.script !== (videoIdea.value?.script ?? "")
              ? formState.script
              : "",
        });
        message[messageType.success](
          t("You've successfully added this video to the cart.")
        );
      } catch (e) {
        console.error(e);
      }
    };

    const saveNotes = async () => {
      if (videoIdea.value) {
        try {
          if (hasEditedScript.value) {
            await updateCartScript({
              text: formState.script,
              cartItemId: cartItemId,
            });
          }

          analyticsTrackVideoRevisedScript(
            formState.script,
            cartScript.value,
            videoIdea.value.title
          );

          message["success"](t("Successfully updated notes for this video."));
        } catch (e) {
          console.error(e);
        }
      }
    };

    /** Handler function when clicking the primary button */
    const handleButtonClick = async () => {
      // If in public view, redirect instead to login, with redirection to the this video
      if (isPublicView.value) {
        return router.push(
          createRedirectedRoute(
            { name: routeNames.login },
            { name: routeNames.videoDetails, params: { id } }
          )
        );
      }

      // Call mutations
      if (isAddedToCart.value) {
        saveNotes();
      } else {
        addToCart();
      }

      // To update notes from BE
      try {
        await refetchCart();
      } catch (e) {
        console.error(e);
      }

      /** 1. If user's latest quotation has an order, ask if he wants to reuse it
       * 2. Else if user has not yet submitted his/her altest quotation, ask  if
       * he/she wants to subscribe to a plan if not yet subscribed
       * 3. Else continue to cart */
      if (latestQuotationHasOrder.value) {
        return openReuseQuotationModal();
      } else if (shouldAskForQuotation.value) {
        return openAskForQuotationModal();
      } else {
        // Redirect to cart
        router.push({ name: routeNames.cart });
      }
    };

    return {
      t,
      // Add To Cart
      addToCart,
      addToCartLoading,
      isAddedToCart,

      // VideoIdea
      loading,
      error,
      videoIdea,

      // Tiktok Videos
      parsedVideoUrls,
      showTiktokColumn: computed(
        () => parsedVideoUrls.value.length || videoIdea.value?.thumbnail
      ),

      // Others
      routeNames,
      formatDurationFromSeconds,

      // Notes related
      formState,
      saveNotes,
      updateCartScriptLoading,
      handleButtonClick,
      buttonLoading: computed(() =>
        isAddedToCart.value
          ? updateCartScriptLoading.value
          : addToCartLoading.value
      ),
      buttonTextLabel: computed(() => {
        if (isAddedToCart.value) {
          return t("Save and Add");
        }

        return lastOrderScript.value
          ? t("Reuse this template")
          : t("Use this template");
      }),
      buttonDisabled: computed(() => {
        if (isAddedToCart.value) {
          return !hasEditedScript.value;
        }

        // Always default to not disabled for safety
        return false;
      }),
      editScript: () => {
        // Load temporary edits on script
        formState.script = formState.temporaryScript;
        formState.isEditingScript = true;
      },
      revertScript: () => {
        // Save temporarily the editing script
        formState.temporaryScript = formState.script;
        // Revert to original script
        formState.script = videoIdea.value?.script || "";
        formState.isEditingScript = false;
      },
      lastOrderScript,

      // Related Videos (Similar videos)
      relatedVideoIdeasList,
      relatedVideoIdeasLoading,
      handleRelatedVideoClick: (id: string) => {
        redirectToVideoDetails({ videoId: id });
      },
      relatedVideoIdeasParsedData,
      videoLengthTextFormat,
    };
  },
  components: {
    PageLayout,
    VideoEmbedCarousel,
    VideoGrid,
    InputTextArea,
    HistoryOutlined,
    EditOutlined,
    ReuseQuotationModal,
  },
  methods: { capitalize, parseVideoUrls, isEmpty },
});
