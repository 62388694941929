import { useQuery } from "@vue/apollo-composable";
import { videoIdeasGql } from "@/api/video/videoIdeas";
import {
  VideoIdeas,
  VideoIdeasVariables,
  VideoIdeas_videoIdeas,
  VideoIdeas_videoIdeas_VideoIdeaResults,
} from "@/api/video/__generated__/VideoIdeas";
import { parseGqlResponse } from "../utils/graphql/responseParser";
import { computed, reactive, ref, watch } from "vue";
import get from "lodash/get";
import { VideoIdeasInput } from "@/../__generated__/globalTypes";
import { DEFAULT_PAGE_SIZE } from "@/shared/utils/constants";
import {
  analyticsTrackVideoSearchLoadMore,
  analyticsTrackVideoSearch,
} from "@/shared/utils/analytics";

const defaultSearchFilter: VideoIdeasInput = {
  query: null,
  purposeId: null,
  typeId: null,
  audienceId: null,
  industryId: null,
  pagination: {
    after: null,
    pageSize: DEFAULT_PAGE_SIZE,
  },
};

export const useVideoIdeas = (
  pageSize = DEFAULT_PAGE_SIZE,
  initialSearchFilter?: Partial<VideoIdeasInput>
) => {
  const searchFilter = reactive<VideoIdeasInput>({
    ...defaultSearchFilter,
    pagination: {
      ...defaultSearchFilter.pagination,
      pageSize: pageSize,
    },
    ...initialSearchFilter,
  });

  const {
    result: videoIdeasResult,
    loading: videoIdeasLoading,
    refetch: videoIdeasRefetch,
    fetchMore,
  } = useQuery<VideoIdeas, VideoIdeasVariables>(
    videoIdeasGql,
    () => ({
      input: searchFilter,
    }),
    () => ({
      fetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true, // to notify loading when refetch is triggered manually
    })
  );

  // parsed data from backend
  const videoIdeasParsedData = computed(() => {
    return parseGqlResponse<VideoIdeas_videoIdeas_VideoIdeaResults>(
      "VideoIdeaResults",
      videoIdeasResult.value
    ).data;
  });

  const videoIdeasPageInfo = computed(
    () => videoIdeasParsedData.value?.pageInfo
  );

  // fetch more
  const videoIdeasFetchMoreLoading = ref(false);
  const videoIdeasFetchMore = async () => {
    // Only proceed if has next page and theres an end cursor
    if (
      videoIdeasPageInfo.value?.hasNextPage &&
      videoIdeasPageInfo.value.endCursor
    ) {
      analyticsTrackVideoSearchLoadMore(searchFilter);

      // set loading, use this to determine if load more is called
      videoIdeasFetchMoreLoading.value = true;

      await fetchMore({
        variables: {
          input: {
            ...searchFilter,
            pagination: {
              after: videoIdeasPageInfo.value.endCursor,
              pageSize: pageSize,
            },
          },
        },
        /**
         * manually update graphql query result
         *
         * this could easily fail because types used in union are incompatible
         * add type assertion to manually update the result type
         */
        updateQuery: (prevResult, { fetchMoreResult }) => {
          /**
           * Clone the previous result then concatenate the fetch more result
           */
          const updatedVideoIdeas = {
            ...prevResult.videoIdeas,
            // use fetchMoreResult page info but use previous as fallback
            pageInfo: get(
              fetchMoreResult?.videoIdeas,
              "pageInfo",
              prevResult?.videoIdeas?.["pageInfo"]
            ),
            // concatenate previous result and fetch more results
            results: [
              ...get(prevResult?.videoIdeas, "results", []),
              ...get(fetchMoreResult?.videoIdeas, "results", []),
            ],

            // use new total count
            totalCount: get(
              fetchMoreResult?.videoIdeas,
              "totalCount",
              prevResult?.videoIdeas?.["totalCount"]
            ),
            __typename: prevResult.videoIdeas?.__typename,
          };

          return {
            videoIdeas: <VideoIdeas_videoIdeas>updatedVideoIdeas,
          };
        },
      });
      videoIdeasFetchMoreLoading.value = false; // reset loading
    }
  };

  /**
   * If have to be queried manually
   *
   * In normal circumstances there's no need to call this manually,
   * any update to the filters will trigger refetch automatically
   */
  const videoIdeasFetch = async () => {
    await videoIdeasRefetch();
  };

  /**
   * GA only - send most used search terms
   * Note: this will not trigger on initial load and there's no data to pass since everything is empty
   */
  watch(searchFilter, (value) => {
    analyticsTrackVideoSearch(value);
  });

  return {
    searchFilter,
    videoIdeasList: computed(() => videoIdeasParsedData.value?.results ?? []),
    videoIdeasLoading,
    videoIdeasFetch,
    videoIdeasFetchMore,
    videoIdeasFetchMoreLoading,
    videoIdeasHasNextPage: computed(
      () => videoIdeasPageInfo.value?.hasNextPage
    ),
  };
};
