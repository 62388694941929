import { computed } from "vue";
import isEmpty from "lodash/isEmpty";
import { cartGql } from "@/api/cart/cart";
import { useQuery } from "@vue/apollo-composable";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { addToCartGql } from "@/api/cart/addToCart";
import { removeFromCartGql } from "@/api/cart/removeFromCart";
import { checkOutOrderGql } from "@/api/cart/checkOutOrder";
import { Cart, Cart_cart_Cart } from "@/api/cart/__generated__/Cart";
import {
  AddToCart,
  AddToCartVariables,
  AddToCart_addToCart_Cart,
} from "@/api/cart/__generated__/AddToCart";
import {
  RemoveFromCart,
  RemoveFromCartVariables,
  RemoveFromCart_removeFromCart_Cart,
} from "@/api/cart/__generated__/RemoveFromCart";

import {
  CheckOutOrder,
  CheckOutOrderVariables,
  CheckOutOrder_checkOutOrder_Order,
} from "@/api/cart/__generated__/CheckOutOrder";
import {
  AddToCartInput,
  CheckOutOrderInput,
  RemoveFromCartInput,
  UpdateCartNotesInput,
  UpdateCartItemInput,
} from "@/../__generated__/globalTypes";
import {
  UpdateCartNotes,
  UpdateCartNotesVariables,
  UpdateCartNotes_updateCartNotes_Cart,
} from "@/api/cart/__generated__/UpdateCartNotes";
import {
  UpdateCartScript,
  UpdateCartScriptVariables,
  UpdateCartScript_updateCartScript_GenericSuccess,
} from "@/api/cart/__generated__/UpdateCartScript";
import { profileGql } from "@/api/profile/profile";
import { updateCartNotesGql } from "@/api/cart/updateCartNotes";
import { updateCartItemGql } from "@/api/cart/updateCartScript";
import { apiErrorCodes } from "@/shared/utils/constants";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import { ordersGql } from "@/api/order/orders";
import { DEFAULT_PAGE_SIZE } from "@/shared/utils/constants";
import { latestOrderGql } from "@/api/order/latestOrder";

export const useCart = (fetchCartListOnMount = true) => {
  const router = useRouter();

  const {
    result: cartResult,
    loading: cartListLoading,
    refetch: refetchCart,
  } = useQuery<Cart>(cartGql, {}, () => ({
    fetchPolicy: "network-only",
    enabled: fetchCartListOnMount,
  }));

  /**
   * add refetchQueries on add and remove cart query, to automatically refresh the cache data
   * cartGql - refresh cart list
   * profileGql - refresh cart count on header
   */
  const commonCartUpdateMutationOption = {
    awaitRefetchQueries: false,
    refetchQueries: [
      // TODO: add, remove, clear cart, still working without cartGql. Not sure why,
      // but removing this fixes the cart clearing before checkout (shows empty cart for split second)
      // { query: cartGql },
      { query: profileGql },
    ],
  };

  const { mutate: addToCartMutate, loading: addToCartLoading } =
    useCustomMutation<AddToCart, AddToCartVariables>(
      addToCartGql,
      commonCartUpdateMutationOption
    );

  const { mutate: removeFromCartMutate, loading: removeFromCartLoading } =
    useCustomMutation<RemoveFromCart, RemoveFromCartVariables>(
      removeFromCartGql,
      commonCartUpdateMutationOption
    );

  const { mutate: checkOutOrderMutate, loading: checkOutOrderLoading } =
    useCustomMutation<CheckOutOrder, CheckOutOrderVariables>(checkOutOrderGql, {
      ...commonCartUpdateMutationOption,
      refetchQueries: [
        ...commonCartUpdateMutationOption.refetchQueries,
        {
          query: latestOrderGql,
        },
        {
          query: ordersGql,
          variables: {
            input: {
              pagination: {
                pageSize: DEFAULT_PAGE_SIZE,
                after: null,
              },
            },
          },
        },
      ],
    });

  const { mutate: updateCartNotesMutate, loading: updateCartNotesLoading } =
    useCustomMutation<UpdateCartNotes, UpdateCartNotesVariables>(
      updateCartNotesGql,
      commonCartUpdateMutationOption
    );

  const { mutate: updateCartScriptMutate, loading: updateCartScriptLoading } =
    useCustomMutation<UpdateCartScript, UpdateCartScriptVariables>(
      updateCartItemGql,
      commonCartUpdateMutationOption
    );

  const parsedCartResponse = computed(() => {
    return parseGqlResponse<Cart_cart_Cart>("Cart", cartResult.value).data;
  });

  const cartList = computed(() => {
    // Sort is needed to keep order consistent and not confuse users for same videos in cart
    return (
      [...(parsedCartResponse.value?.items ?? [])].sort(
        (a, b) => Number(a?.id) - Number(b?.id)
      ) ?? []
    );
  });

  const cartNotes = computed(() => {
    return parsedCartResponse.value?.notes;
  });

  const handleAddToCart = async (input: AddToCartInput) => {
    const addToCartResponse = await addToCartMutate({
      input,
    });

    const parsedResponse = parseGqlResponse<AddToCart_addToCart_Cart>(
      "Cart",
      addToCartResponse
    );

    console.log("parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !addToCartResponse) {
      throw new Error("Failed to add to cart");
    }

    return parsedResponse.data;
  };

  const handleRemoveFromCart = async (input: RemoveFromCartInput) => {
    const removeFromCartResponse = await removeFromCartMutate({
      input,
    });

    const parsedResponse = parseGqlResponse<RemoveFromCart_removeFromCart_Cart>(
      "Cart",
      removeFromCartResponse
    );

    console.log("parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !removeFromCartResponse) {
      throw new Error("failed to remove from cart");
    }

    return parsedResponse.data;
  };

  const handleCheckOutOrder = async (
    input: CheckOutOrderInput,
    file?: File
  ) => {
    const checkOutOrderResponse = await checkOutOrderMutate({
      input,
      file,
    });

    const parsedResponse = parseGqlResponse<CheckOutOrder_checkOutOrder_Order>(
      "Order",
      checkOutOrderResponse,
      apiErrorCodes.NO_QUOTATION_YET_SUBMITTED_ERROR
    );

    console.log("parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !checkOutOrderResponse) {
      // If no Quotation yet when ordering, redirect to questionnaire
      if (
        parsedResponse.error?.errors.some(
          (error) =>
            error.code ==
            apiErrorCodes.NO_QUOTATION_YET_SUBMITTED_ERROR.toString()
        )
      ) {
        router.push({ name: routeNames.quote });
      }

      throw new Error("failed to checkout order");
    }

    return parsedResponse.data;
  };

  const updateCartNotes = async (input: UpdateCartNotesInput) => {
    const updateCartNotesResponse = await updateCartNotesMutate({ input });

    const parsedResponse =
      parseGqlResponse<UpdateCartNotes_updateCartNotes_Cart>(
        "Cart",
        updateCartNotesResponse
      );

    if (parsedResponse.error) {
      throw parsedResponse.error;
    }

    return parsedResponse.data;
  };

  const updateCartScript = async (input: UpdateCartItemInput) => {
    const updateCartScriptResponse = await updateCartScriptMutate({ input });

    const parsedResponse =
      parseGqlResponse<UpdateCartScript_updateCartScript_GenericSuccess>(
        "GenericSuccess",
        updateCartScriptResponse
      );

    if (parsedResponse.error) {
      throw parsedResponse.error;
    }

    return parsedResponse.data;
  };

  /**
   * Gets the custom script saved in backend for a specific video.
   * @param cartItemId The ID of the Cart Item.
   */
  const getCustomScript = (cartItemId: string) => {
    const savedScript = cartList.value.find(
      (cartItem) => cartItem?.id === cartItemId
    );

    return savedScript?.text ?? "";
  };

  return {
    cartNotes,
    cartList,
    cartListLoading,
    handleAddToCart,
    addToCartLoading,
    handleRemoveFromCart,
    removeFromCartLoading,
    handleCheckOutOrder,
    checkOutOrderLoading,
    updateCartNotes,
    updateCartNotesLoading,
    updateCartScript,
    updateCartScriptLoading,
    getCustomScript,
    refetchCart,
  };
};
