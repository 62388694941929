import { i18nTranslate } from "@/plugins/i18n";
import { useSubscriptionPlans } from "@/shared/composables/useSubscriptionPlans";
import { computed, onMounted, Ref } from "vue";
import { LatestQuotation_latestQuotation_Quotation } from "@/api/quotations/__generated__/LatestQuotation";
import { Quotation_quotation_Quotation } from "@/api/quotations/__generated__/Quotation";
import { config } from "@/shared/utils/config";
import { useQuotationPrices } from "@/shared/composables/useQuotationPrices";
import { CustomRadioOption } from "@/shared/components/CustomRadioOptions.vue";

export type UseQuotationOptionsParameters = {
  quotationRef: Ref<LatestQuotation_latestQuotation_Quotation | null>;
};

/** Defined here as this is not restricted by pricing. */
export const orderReasonsOptions: CustomRadioOption[] = [
  {
    label: i18nTranslate("Video Ads"),
    labelIcon: "megaphone",
    value: "ADS",
  },
  {
    label: i18nTranslate("Tiktok"),
    labelIcon: "tiktok",
    value: "TIKTOK",
  },
  {
    label: i18nTranslate("Instagram"),
    labelIcon: "instagram",
    value: "INSTAGRAM",
  },
  {
    label: i18nTranslate("Facebook"),
    labelIcon: "facebook",
    value: "FACEBOOK",
  },
];

/**
 * Options here have `true` values IF they correspond to USING the add-on.
 * This can invert in some instances.
 *
 * For example:
 * - Can you do your own script?
 * -- Yes (no add-on, `"false"`)
 * -- No (USE add-on, `"true"`)
 */
export const useQuotationOptions = (
  options?: UseQuotationOptionsParameters
) => {
  const quotationRef = options?.quotationRef;
  const hasPromoCode = computed(() => !!quotationRef?.value?.promoCode);

  const { getFormattedQuotationPrice, quotationPricesLoading } =
    useQuotationPrices();

  const {
    subscriptionPlans,
    subscriptionPlansLoading,
    refetchSubscriptionPlans,
  } = useSubscriptionPlans(true);

  const scriptAddOnOptions = computed<CustomRadioOption[]>(() => [
    {
      label: i18nTranslate("Yes"),
      value: "false",
      labelDescription: i18nTranslate(
        "You will research video ideas and write instructions and script for the model creator."
      ),
      subLabel: i18nTranslate("DEFAULT"),
      labelIcon: "pen",
    },
    {
      label: i18nTranslate("No"),
      value: "true",
      labelDescription: i18nTranslate(
        "Our Content Writer will research and construct the video idea for you."
      ),
      subLabel: getFormattedQuotationPrice(
        "ORDER_VIDEO_ADD_ON_STRATEGIST_PRICE",
        "+{amount}"
      ),
      subLabelExtra: i18nTranslate("/ video"),
      subLabelColor: "pink",
      labelIcon: "lightBulb",
    },
  ]);

  const extendedLengthAddOnOptions = computed<CustomRadioOption[]>(() => [
    {
      label: i18nTranslate("30 seconds or less"),
      value: "false",
      labelIcon: "shortVideoIcon",
      videoUrls: ["https://player.vimeo.com/video/827973735?h=c5c242e742"],
      subLabel: i18nTranslate("DEFAULT"),
    },
    {
      label: i18nTranslate("30-45 seconds"),
      value: "true",
      labelIcon: "longVideoIcon",
      videoUrls: ["https://player.vimeo.com/video/827973782?h=e5e73ec677"],
      subLabel: getFormattedQuotationPrice(
        "ORDER_VIDEO_ADD_ON_LENGTH_PRICE",
        "+{amount}"
      ),
      subLabelExtra: i18nTranslate("/ video"),
      subLabelColor: "pink",
      disabled: hasPromoCode?.value,
    },
  ]);

  const choosePerformersAddOnOptions = computed<CustomRadioOption[]>(() => [
    {
      label: i18nTranslate("No"),
      value: "false",
      labelDescription: i18nTranslate(
        "{merchantBrand} will choose the model creator who will shoot your videos.",
        {
          merchantBrand: config.merchantBrandShortName,
        }
      ),
      subLabel: i18nTranslate("DEFAULT"),
      labelIcon: "userLocked",
    },
    {
      label: i18nTranslate("Yes"),
      value: "true",
      labelDescription: i18nTranslate(
        "You will choose the model creators who will shoot your videos."
      ),
      subLabel: getFormattedQuotationPrice(
        "ORDER_VIDEO_ADD_ON_PC_CHOOSE_PRICE",
        "+{amount}"
      ),
      subLabelExtra: i18nTranslate("/ video"),
      subLabelColor: "pink",
      labelIcon: "userSearch",
    },
  ]);

  const usePremiumPerformersAddOnOptions = computed<CustomRadioOption[]>(() => [
    {
      label: i18nTranslate("Top Model Creator"),
      value: "false",
      labelIcon: "topPerformerIcon",
      labelDescription: i18nTranslate(
        "From a pool of thousands of model creators, they are the top 10% of the group."
      ),
      subLabel: i18nTranslate("FREE"),
      videoUrls: ["https://player.vimeo.com/video/827974192?h=b0f3c1a608"],
    },
    {
      label: i18nTranslate("Premium Model Creator"),
      value: "true",
      labelIcon: "premiumPerformerIcon",
      labelDescription: i18nTranslate(
        "These are the elite model creators - always on-demand, 10-star rating from clients and the best camera quality"
      ),
      subLabel: getFormattedQuotationPrice(
        "ORDER_VIDEO_ADD_ON_PC_PREMIUM_PRICE",
        "+{amount}"
      ),
      subLabelExtra: i18nTranslate("/ video"),
      subLabelColor: "pink",
      videoUrls: ["https://player.vimeo.com/video/827974054?h=00032ecf1f"],
    },
  ]);

  const useExpressAddOnOptions = computed<CustomRadioOption[]>(() => [
    {
      label: i18nTranslate("Regular: 7 biz days"),
      value: "false",
      labelIcon: "regular",
      subLabel: i18nTranslate("DEFAULT"),
      disabled: hasPromoCode?.value,
    },
    {
      label: i18nTranslate("Express: 4 biz days"),
      value: "true",
      labelIcon: "express",
      subLabel: getFormattedQuotationPrice(
        "ORDER_VIDEO_ADD_ON_EXPRESS_PRICE",
        "+{amount}"
      ),
      subLabelExtra: i18nTranslate("/ video"),
      subLabelColor: "pink",
      disabled: hasPromoCode?.value,
    },
  ]);

  const usePerformerTravelAddOnOptions = computed<CustomRadioOption[]>(() => [
    {
      label: i18nTranslate("No"),
      value: "false",
      labelIcon: "noTravelIcon",
      subLabel: i18nTranslate("DEFAULT"),
      videoUrls: ["https://player.vimeo.com/video/827974003?h=ce183a9b18"],
    },
    {
      label: i18nTranslate("Yes"),
      value: "true",
      videoUrls: ["https://player.vimeo.com/video/827973693?h=3b3fdc0500"],
      labelIcon: "withTravelIcon",
      labelDescription: i18nTranslate(
        "This covers 2 hours shooting model creator fee AND the transportation cost of the model creator. Note that that this is charged per # of model creator."
      ),
      subLabel: getFormattedQuotationPrice(
        "ORDER_VIDEO_ADD_ON_PC_TRAVEL_PRICE",
        "+{amount}"
      ),
      subLabelExtra: i18nTranslate("/ 2 hours shooting of 1 model creator"),
      useSublabelColorInExtra: true,
      subLabelColor: "pink",
    },
  ]);

  const sendProductsAddOnOptions = computed<CustomRadioOption[]>(() => [
    {
      label: i18nTranslate("No"),
      value: "false",
      labelDescription: i18nTranslate(
        "My product is a service/app/digital product. I don't need to ship anything."
      ),
    },
    {
      label: i18nTranslate("Yes"),
      value: "true",
      labelDescription: i18nTranslate(
        "I will ship the products to the model creator."
      ),
      labelIcon: "truck",
    },
  ]);

  const useSameDayDeliveryOptions = computed<CustomRadioOption[]>(() => [
    {
      label: i18nTranslate("No"),
      value: "false",
      labelDescription: i18nTranslate("I can ship through courier."),
      subLabel: i18nTranslate("DEFAULT"),
    },
    {
      label: i18nTranslate("Yes"),
      value: "true",
      labelDescription: i18nTranslate(
        "I need to ship it right away. (Because my product is consumable/ hard to ship through courier etc)."
      ),
      subLabel: getFormattedQuotationPrice(
        "ORDER_VIDEO_ADD_ON_SAME_DAY_DELIVERY_PRICE",
        "+{amount}"
      ),
      subLabelColor: "pink",
      subLabelExtra: i18nTranslate(
        "/ creator, excluding Shipping Fee care for you"
      ),
      useSublabelColorInExtra: true,
      labelIcon: "truck",
    },
  ]);

  const returnProductsAddOnOptions = computed<CustomRadioOption[]>(() => [
    {
      label: i18nTranslate("No"),
      value: "false",
      subLabel: i18nTranslate("DEFAULT"),
      labelDescription: i18nTranslate(
        "The model creator/s can keep the product."
      ),
    },
    {
      label: i18nTranslate("Yes"),
      value: "true",
      labelDescription: i18nTranslate("I need the products returned to me."),
      subLabel: getFormattedQuotationPrice(
        "ORDER_VIDEO_ADD_ON_PRODUCT_RETURN_PRICE",
        "+{amount}"
      ),
      subLabelColor: "pink",
      subLabelExtra: i18nTranslate(
        "/ creator, excluding Shipping Fee care for you"
      ),
      useSublabelColorInExtra: true,
      labelIcon: "truck",
    },
  ]);

  const revisionRequestsAddOnOptions = computed<CustomRadioOption[]>(() => [
    {
      label: i18nTranslate("Yes, I agree."),
      value: "false",
      subLabel: i18nTranslate("DEFAULT"),
      labelDescription: i18nTranslate(
        "I will not ask for a revision unless what's stated below*"
      ),
    },
    {
      label: i18nTranslate("No, I want to have revisions."),
      value: "true",

      disabled: true,
      subLabel: i18nTranslate("ADD-ON NOT AVAILABLE"),
      labelIcon: "refresh",
    },
  ]);

  const subscriptionPlansOptions = computed(() =>
    subscriptionPlans.value.map((plan) => ({
      value: plan.id.toString(),
      videoCount: plan.maxVideoCount,
      performerCount: plan.maxPerformerCount,
      testimonialLockInMonths: plan.testimonialLockInMonths,
    }))
  );

  const useTestimonialPackageOptions = computed<CustomRadioOption[]>(() => [
    {
      label: i18nTranslate("No"),
      value: "false",
      subLabel: getFormattedQuotationPrice("ORDER_VIDEO_BASE_PRICE"),
      subLabelExtra: i18nTranslate("/ video"),
      labelDescription: i18nTranslate(
        "I don't want to give feedback and commit more than 1 month."
      ),
    },
    {
      label: i18nTranslate("Yes"),
      value: "true",
      labelDescription: i18nTranslate(
        "I agree to the terms and conditions for this package."
      ),
      subLabel: getFormattedQuotationPrice(
        "ORDER_VIDEO_BASE_TESTIMONIAL_PRICE"
      ),
      subLabelExtra: i18nTranslate("/ video"),
      subLabelColor: "pink",
      labelIcon: "receipt",
    },
  ]);

  onMounted(() => {
    refetchSubscriptionPlans();
  });

  return {
    orderReasonsOptions,
    scriptAddOnOptions,
    extendedLengthAddOnOptions,
    choosePerformersAddOnOptions,
    usePremiumPerformersAddOnOptions,
    useExpressAddOnOptions,
    usePerformerTravelAddOnOptions,
    sendProductsAddOnOptions,
    useSameDayDeliveryOptions,
    returnProductsAddOnOptions,
    revisionRequestsAddOnOptions,
    subscriptionPlansOptions,
    useTestimonialPackageOptions,

    quotationOptionsLoading: computed(
      () => subscriptionPlansLoading.value || quotationPricesLoading.value
    ),
  };
};

type AddOnData = {
  key: keyof Quotation_quotation_Quotation;
  label: string;
  price: number;
  /** Affects computation in template. If undefined, will view as one-time fee (no multiplier) */
  multiplier?: "video" | "creator";
  /** Default is per `video`. */
  priceUnit?: string;
};

export const userQuotationAddOnsList = (options?: {
  quotationRef?: Ref<Quotation_quotation_Quotation | null>;
}) => {
  const { getQuotationPrice, getQuotationMultiplier } = useQuotationPrices({
    quotationRef: options?.quotationRef,
  });

  const addOnsList = computed<AddOnData[]>(() => [
    {
      key: "hireStrategist",
      label: "Hire Writer",
      price: getQuotationPrice("ORDER_VIDEO_ADD_ON_STRATEGIST_PRICE"),
      multiplier: getQuotationMultiplier("ORDER_VIDEO_ADD_ON_STRATEGIST_PRICE"),
    },
    {
      key: "useExtendedVideoLength",
      label: "Long Videos",
      price: getQuotationPrice("ORDER_VIDEO_ADD_ON_LENGTH_PRICE"),
      multiplier: getQuotationMultiplier("ORDER_VIDEO_ADD_ON_LENGTH_PRICE"),
    },
    {
      key: "choosePerformers",
      label: "Choose Model Creators",
      price: getQuotationPrice("ORDER_VIDEO_ADD_ON_PC_CHOOSE_PRICE"),
      multiplier: getQuotationMultiplier("ORDER_VIDEO_ADD_ON_PC_CHOOSE_PRICE"),
    },
    {
      key: "usePremiumPerformers",
      label: "Premium Model Creators",
      price: getQuotationPrice("ORDER_VIDEO_ADD_ON_PC_PREMIUM_PRICE"),
      multiplier: getQuotationMultiplier("ORDER_VIDEO_ADD_ON_PC_PREMIUM_PRICE"),
    },
    {
      key: "useExpress",
      label: "Express",
      price: getQuotationPrice("ORDER_VIDEO_ADD_ON_EXPRESS_PRICE"),
      multiplier: getQuotationMultiplier("ORDER_VIDEO_ADD_ON_EXPRESS_PRICE"),
    },
    {
      key: "usePerformerTravel",
      label: "Location Visit",
      price: getQuotationPrice("ORDER_VIDEO_ADD_ON_PC_TRAVEL_PRICE"),
      multiplier: getQuotationMultiplier("ORDER_VIDEO_ADD_ON_PC_TRAVEL_PRICE"),
      priceUnit: "2 hrs, each model creator",
    },
    {
      key: "useSameDayDelivery",
      label: "Same-day Delivery",
      price: getQuotationPrice("ORDER_VIDEO_ADD_ON_SAME_DAY_DELIVERY_PRICE"),
      multiplier: getQuotationMultiplier(
        "ORDER_VIDEO_ADD_ON_SAME_DAY_DELIVERY_PRICE"
      ),
    },
    {
      key: "returnProducts",
      label: "Return Products",
      price: getQuotationPrice("ORDER_VIDEO_ADD_ON_PRODUCT_RETURN_PRICE"),
      multiplier: getQuotationMultiplier(
        "ORDER_VIDEO_ADD_ON_PRODUCT_RETURN_PRICE"
      ),
    },
    {
      key: "revisionRequests",
      label: "Revision Requests",
      price: getQuotationPrice("ORDER_VIDEO_ADD_ON_REVISION_REQUESTS_PRICE"),
      multiplier: getQuotationMultiplier(
        "ORDER_VIDEO_ADD_ON_REVISION_REQUESTS_PRICE"
      ),
    },
  ]);

  const getFilteredAddOnsList = (quotation) =>
    addOnsList.value.filter((addOn) => quotation?.[addOn.key]);

  return {
    addOnsList,
    getFilteredAddOnsList,
  };
};
