import gql from "graphql-tag";

export const removeFromCartGql = gql`
  mutation RemoveFromCart($input: RemoveFromCartInput) {
    removeFromCart(input: $input) {
      ... on Cart {
        id
        items {
          id
          text
          videoIdea {
            id
            title
          }
        }
        notes
        itemCount
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
