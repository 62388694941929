import gql from "graphql-tag";

export const latestOrderGql = gql`
  query LatestOrder {
    latestOrder {
      ... on Order {
        id
        created
        status {
          id
          key
        }
        quotation {
          id
        }
      }

      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
