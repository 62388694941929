import { videoIdeaGql } from "@/api/videoIdea/videoIdea";
import { useQuery } from "@vue/apollo-composable";
import { computed } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import {
  VideoIdea,
  VideoIdeaVariables,
  VideoIdea_videoIdea_VideoIdea,
} from "@/api/videoIdea/__generated__/VideoIdea";
import { HideIfErrorCode } from "@/shared/utils/graphql/errorHandler";

export const useVideoIdea = (input: VideoIdeaVariables, hideErrors = false) => {
  const videoIdeaQuery = useQuery<VideoIdea, VideoIdeaVariables>(
    videoIdeaGql,
    input,
    { fetchPolicy: "no-cache" }
  );

  const videoIdea = computed(() => {
    const videoIdeaGqlResponse =
      parseGqlResponse<VideoIdea_videoIdea_VideoIdea>(
        "VideoIdea",
        videoIdeaQuery.result.value,
        hideErrors ? HideIfErrorCode.ALL_ERRORS : null
      );

    return videoIdeaGqlResponse?.data;
  });

  return {
    videoIdea,
    loading: videoIdeaQuery.loading,
    error: videoIdeaQuery.error,
  };
};
