import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "grid grid-cols-1 md:grid-cols-5 gap-10" }
const _hoisted_3 = {
  key: 0,
  class: "col-span-full lg:col-span-2"
}
const _hoisted_4 = { class: "text-right mt-14" }
const _hoisted_5 = {
  key: 0,
  class: "mt-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_PageLayout = _resolveComponent("PageLayout")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_VideoEmbedCarousel = _resolveComponent("VideoEmbedCarousel")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_HistoryOutlined = _resolveComponent("HistoryOutlined")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_InputTextArea = _resolveComponent("InputTextArea")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_VideoGrid = _resolveComponent("VideoGrid")!
  const _component_ReuseQuotationModal = _resolveComponent("ReuseQuotationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_PageLayout, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_skeleton, { active: "" })
          ]),
          _: 1
        }))
      : (_ctx.videoIdea)
        ? (_openBlock(), _createBlock(_component_PageLayout, {
            key: 1,
            title: _ctx.videoIdea.title,
            class: "video-detail-page",
            "back-button": "",
            onOnBack: _ctx.$router.back
          }, {
            subtitle: _withCtx(() => [
              _createVNode(_component_a_typography_title, { level: 5 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Result for Other Businesses")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_typography_paragraph, {
                class: "page-layout__header__subtitle text-lg",
                innerHTML: _ctx.videoIdea.longDescription
              }, null, 8, ["innerHTML"]),
              (_ctx.videoIdea.duration)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                    _createVNode(_component_a_typography_text, {
                      class: "page-layout__header__subtitle",
                      strong: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Video Length: ")), 1)
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.videoLengthTextFormat(_ctx.videoIdea.duration)), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_2, [
                  (_ctx.showTiktokColumn)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        (_ctx.parsedVideoUrls.length)
                          ? (_openBlock(), _createBlock(_component_VideoEmbedCarousel, {
                              key: 0,
                              label: _ctx.t('How others do it'),
                              embedUrls: _ctx.parsedVideoUrls
                            }, null, 8, ["label", "embedUrls"]))
                          : (_ctx.videoIdea.thumbnail)
                            ? (_openBlock(), _createBlock(_component_a_image, {
                                key: 1,
                                width: 325,
                                height: 575,
                                preview: false,
                                src: _ctx.videoIdea.thumbnail,
                                class: "video-detail-page__thumbnail"
                              }, null, 8, ["src"]))
                            : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    class: _normalizeClass(["col-span-full lg:col-span-3", [!_ctx.showTiktokColumn && 'lg:col-span-full']])
                  }, [
                    _createVNode(_component_a_space, {
                      direction: "vertical",
                      size: 26,
                      class: "w-full"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_a_typography_title, {
                            level: 5,
                            class: "video-detail-page__how-to-order"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("How to order")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_typography_paragraph, {
                            class: "mb-0",
                            strong: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t(`Edit the script below to give more details about your brand or write
                   your own script. Give clear instructions to the performer creator.`)), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("div", null, [
                          _createVNode(_component_a_button, {
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (
                  _ctx.formState.isEditingScript ? _ctx.revertScript() : _ctx.editScript()
                )),
                            type: _ctx.formState.isEditingScript ? 'secondary' : 'primary',
                            class: "my-4"
                          }, {
                            icon: _withCtx(() => [
                              (_ctx.formState.isEditingScript)
                                ? (_openBlock(), _createBlock(_component_HistoryOutlined, { key: 0 }))
                                : (_openBlock(), _createBlock(_component_EditOutlined, { key: 1 }))
                            ]),
                            default: _withCtx(() => [
                              _createTextVNode(" " + _toDisplayString(_ctx.formState.isEditingScript
                    ? _ctx.t("Use Original Script")
                    : _ctx.t("Edit Script")), 1)
                            ]),
                            _: 1
                          }, 8, ["type"]),
                          _createVNode(_component_InputTextArea, {
                            name: "instructions",
                            value: _ctx.formState.script,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.script) = $event)),
                            class: "video-detail-page__instruction-textarea",
                            disabled: !_ctx.formState.isEditingScript
                          }, null, 8, ["value", "disabled"]),
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_a_button, {
                              type: "primary",
                              onClick: _ctx.handleButtonClick,
                              loading: _ctx.buttonLoading,
                              disabled: _ctx.buttonDisabled
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.buttonTextLabel), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick", "loading", "disabled"])
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ], 2)
                ])
              ]),
              (_ctx.relatedVideoIdeasLoading || !_ctx.isEmpty(_ctx.relatedVideoIdeasList))
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_a_typography_title, { level: 2 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Similar Videos You Might Like")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_VideoGrid, {
                      "video-ideas": _ctx.relatedVideoIdeasList,
                      loading: _ctx.relatedVideoIdeasLoading,
                      onOnItemClick: _ctx.handleRelatedVideoClick
                    }, null, 8, ["video-ideas", "loading", "onOnItemClick"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["title", "onOnBack"]))
        : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ReuseQuotationModal)
    ]))
  ], 64))
}