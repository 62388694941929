import gql from "graphql-tag";

export const cartGql = gql`
  query Cart {
    cart {
      ... on Cart {
        id
        items {
          id
          text
          videoIdea {
            id
            title
            thumbnail
            shortDescription
            longDescription
            isActive
            script
            videoUrls
            types {
              id
              name
            }
            purposes {
              id
              name
            }
            audiences {
              id
              name
            }
            industries {
              id
              name
            }
            duration
            created
          }
        }
        notes
        itemCount
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
