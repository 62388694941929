import gql from "graphql-tag";

export const videoIdeasGql = gql`
  query VideoIdeas($input: VideoIdeasInput!) {
    videoIdeas(input: $input) {
      ... on VideoIdeaResults {
        results {
          id
          title
          thumbnail
          shortDescription
          longDescription
          isActive
          script
          videoUrls
          sortWeight
          types {
            id
            name
          }
          purposes {
            id
            name
          }
          audiences {
            id
            name
          }
          industries {
            id
            name
          }
          duration
          created
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
