import gql from "graphql-tag";

export const checkOutOrderGql = gql`
  mutation CheckOutOrder($input: CheckOutOrderInput, $file: Upload) {
    checkOutOrder(input: $input, file: $file) {
      ... on Order {
        id
        status {
          id
          name
          key
          sortOrder
        }
        notes
        scriptLink
        videoOutputLink
        creatorsLink
        items {
          id
          title
        }
        notes
        shipmentInstructions
        moodboardFile
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage

          ... on CartItemLimitExceededError {
            limit
          }

          ... on CartItemMinLimitNotReachedError {
            limit
          }
        }
      }
    }
  }
`;
